// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authUrl: 'https://elb.virtsel-fidere-api.com.br',
  baseUrl: 'https://elb.virtsel-fidere-api.com.br',
  publisherUrl: 'https://elb.virtsel-fidere-api.com.br',
  imagemUrl: 'https://images.virtsel-fidere-api.com.br',
  googleMapsApiKey: 'AIzaSyAdejfjw-j9ce-adSKwIPsy2XrJrI4PBLI',
  encryptionKey: 'secret'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
